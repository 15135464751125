<template>
    <router-link :to="'./catalog/view?id=' + product.id" class="card card-catalog">
        <div class="card-header style-basic">
            <img v-lazy="getAvatarSrc(this.product.id)" :alt="'Штукатурка ' + product.nameru">
            <div v-if="product.recommended" class="badge style-secondary-active card-badge left">
                <i class="fa fa-check"></i>
            </div>
        </div>
        <div class="card-body p-0">
            <p class="card-title">
                {{product.name}}
            </p>
            <div class="card-text">
                {{product.shortDescription}}
            </div>
            <p class="card-price" :data-discount="doDiscount">
                ~
                <s>{{priceDiscounted}} ₽</s>
                {{pricePerMeter}} ₽ / м<sup>2</sup>

                <span>-10%</span>
            </p>

            <button class="card-btn btn btn-sm style-secondary-basic default$">
                Подробнее
            </button>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'ProductCard',
    props: [
        'product'
    ],
    methods: {
        getAvatarSrc: function(product) {
            return require(`../assets/img/products/${product}/01.jpeg`)
        }
    },
    data: function() {
        // find min price
        const minQuantityPrice = Math.min(...Object.values(this.product.priceBuy))
       
        // find quantity by min price
        let minQuantity = '?кг'
        Object.keys(this.product.priceBuy).forEach(q => {
            if(this.product.priceBuy[q] == minQuantityPrice) {
                minQuantity = q;
                return;
            }
        })
        const minQuantityRaw = parseInt(minQuantity.replace('kg', ''));

        // calculate price per square meter
        const pricePerMeter = Math.trunc((minQuantityPrice / minQuantityRaw) * this.product.consumption);

        return {
            quantity: minQuantity.replace('kg','кг'),
            doDiscount: false,
            price: minQuantityPrice,
            priceDiscounted: Math.floor(minQuantityPrice * 1.1),
            pricePerMeter,
        }
    }
}
</script>
