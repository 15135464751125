<template>
    <section id="products">
        <div :class="'section-content section-content-xs style-' + sectionStyle">
            <div class="container">
                <!-- Title -->
                <div class="row">
                    <div class="col-12 text-center text-md-left">
                        <p class="h1 styled-header font-weight-bold mb-4">Каталог</p>
                    </div>
                </div>
                <!-- Nav -->
                <div class="row mb-3">
                    <div class="col-12">
                        <ul class="nav nav-categories d-flex justify-content-center justify-content-md-start mb-3" id="pills-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="tab-flat" data-toggle="pill" href="#page-flat" role="tab" aria-controls="page-flat" aria-selected="true">Гладкие</a>
                            </li>
                            <li class="nav-item mx-2">
                                <a class="nav-link" id="tab-silk" data-toggle="pill" href="#page-silk" role="tab" aria-controls="page-silk" aria-selected="false">Шелковые</a>
                            </li>
                            <li class="nav-item mx-2">
                                <a class="nav-link" id="tab-sand" data-toggle="pill" href="#page-sand" role="tab" aria-controls="page-sand" aria-selected="false">Песочные</a>
                            </li>
                            <li class="nav-item mx-2">
                                <a class="nav-link" id="tab-factural" data-toggle="pill" href="#page-factural" role="tab" aria-controls="page-factural" aria-selected="false">Фактурные</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Tabs -->
                <div class="tab-content" id="products-tabContent">
                    <!-- Factural -->
                    <div class="tab-pane fade" id="page-factural" role="tabpanel" aria-describedby="tab-factural">
                        <div class="row">
                            <div v-for="product in factural" :key="product.id" class="col-12 col-md-6 col-lg-4 mb-4">
                                <ProductCard :product="product"/>
                            </div>
                        </div>
                    </div>

                    <!-- Silk -->
                    <div class="tab-pane fade" id="page-silk" role="tabpanel" aria-describedby="tab-silk">
                        <div class="row">
                            <div v-for="product in silk" :key="product.id" class="col-12 col-md-6 col-lg-4 mb-4">
                                <ProductCard :product="product"/>
                            </div>
                        </div>
                    </div>

                    <!-- Sand -->
                    <div class="tab-pane fade" id="page-sand" role="tabpanel" aria-describedby="tab-sand">
                        <div class="row">
                            <div v-for="product in sand" :key="product.id" class="col-12 col-md-6 col-lg-4 mb-4">
                                <ProductCard :product="product"/>
                            </div>
                        </div>
                    </div>

                    <!-- Flat -->
                    <div class="tab-pane fade active show" id="page-flat" role="tabpanel" aria-describedby="tab-flat">
                        <div class="row">
                            <div v-for="product in flat" :key="product.id" class="col-12 col-md-6 col-lg-4 mb-4">
                                <ProductCard :product="product"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ProductCard from '../ProductCard.vue'

export default {
    name: 'CatalogSection',
    components: {
        'ProductCard': ProductCard
    },
    props: {
        sectionStyle: {
            type: String,
            default: 'basic'
        }
    },
    data: function() {
        return {
            factural: window.data.products.filter(product => product.category.includes('factural')),
            silk: window.data.products.filter(product => product.category.includes('silk')),
            sand: window.data.products.filter(product => product.category.includes('sand')),
            flat: window.data.products.filter(product => product.category.includes('flat')),
            multicolor: window.data.products.filter(product => product.category.includes('multicolor')),
        }
    }
}
</script>
